/**
 * Reset - Resets all browsers down to common default settings
 *
 * @credits Eric Meyer's CSS Reset <http://meyerweb.com/eric/thoughts/2007/05/01/reset-reloaded/>
 * @package WPEvoLve
 * @subpackage Media
 */

/* Eric Meyer Reset Reloaded */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, code,
del, dfn, em, img, q, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td { margin: 0px; padding: 0px; border: 0px; font-weight: inherit; font-style: inherit; font-size: 100%; font-family: inherit; vertical-align: baseline; }

/* Removes dotted line when focus */
:focus { outline: none; }

/* Tables still need 'cellspacing="0"' in the markup. */
table { border-collapse: separate; border-spacing: 0px; }
caption, th, td { text-align: left; font-weight: normal; }
table, td, th { vertical-align: middle; }

/* Remove possible quote marks (") from <q>, <blockquote>. */
blockquote:before, blockquote:after, q:before, q:after { content: ""; }
blockquote, q { quotes: "" ""; }

/* Remove annoying border on linked images. */
a img { border: none; }

/* Clearing floats without extra markup
   Based on How To Clear Floats Without Structural Markup by PiE
   [http://www.positioniseverything.net/easyclearing.html] */
.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.clearfix { display: block; }

/* Regular clearing, just in case! */
.clear { clear: both; }