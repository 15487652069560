body {
    background-color: #e5e5e5;
}

@media (min-width: 1220px) {
    .container,
    #wrapper {
        width: 1200px!important;
    }
}

#gmap {
    width: 100%;
    margin: 0 auto;
    height: 415px
}

@media only screen and (min-width: 768px) {
    .link-effect a:hover span,
    .link-effect a:focus span {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

.entry-content .thumbnail-post:hover img {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

.entry-content .thumbnail-post:hover .mask {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.entry-content .thumbnail-post:hover div.icon {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    top: 50%;
    margin-top: -21px;
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    -ms-transition-delay: 0.1s;
    transition-delay: 0.1s;
}


/**
 * 1 column (content) fixed layout
 * 
 * @package WPEvoLve
 * @subpackage Layouts
 * @beta
 */

ul.nav-menu a {
    color: #fff;
    text-shadow: 0 1px 0px #333;
}

ul.nav-menu li.nav-hover ul {
    background: #505050;
}

ul.nav-menu ul li a {
    border-bottom-color: #484848;
}

ul.nav-menu ul li:hover>a,
ul.nav-menu li.current-menu-item>a,
ul.nav-menu li.current-menu-ancestor>a {
    border-top-color: #666!important;
}

ul.nav-menu li.current-menu-ancestor li.current-menu-item>a,
ul.nav-menu li.current-menu-ancestor li.current-menu-parent>a {
    border-top-color: #666;
}

ul.nav-menu ul {
    border: 1px solid #444;
    border-bottom: 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1);
    -box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1);
}

ul.nav-menu li {
    border-left-color: #444;
    border-right-color: #666;
}

.menu-header {
    background: #505050;
    background: url(../img/dark/trans.png) 0px -7px repeat-x, -moz-linear-gradient(center top, #606060 20%, #505050 100%);
    background: url(../img/dark/trans.png) 0px -7px repeat-x, -webkit-gradient(linear, left top, left bottom, color-stop(.2, #606060), color-stop(1, #505050)) !important;
    background: url(../img/dark/trans.png) 0px -7px repeat-x, -o-linear-gradient(top, #606060, #505050) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#606060', endColorstr='#505050');
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
    -box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
    color: #fff;
    text-shadow: 0 1px 0px #000;
    border-color: #222;
}

body #header.sticky-header a.logo-url-text {
    color: #fff;
}

ul.nav-menu ul {
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 2px rgba(255, 255, 255, 0.05) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1)!important;
    -box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 2px rgba(255, 255, 255, 0.05) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1)!important;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 2px rgba(255, 255, 255, 0.05) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1)!important;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 2px rgba(255, 255, 255, 0.05) inset, 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 1px 2px rgba(0, 0, 0, 0.1)!important;
}

ul.nav-menu li.current-menu-item,
ul.nav-menu li.current-menu-ancestor,
ul.nav-menu li:hover {
    border-right-color: #666!important;
}

ul.nav-menu>li.current-menu-item,
ul.nav-menu>li.current-menu-ancestor,
ul.nav-menu li.current-menu-item>a,
ul.nav-menu li.current-menu-ancestor>a {
    background-color: rgba(0, 0, 0, 0.1)!important;
}

body #header.sticky-header {
    background: rgba(80, 80, 80, 0.95) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

#wrapper .dd-container .dd-selected-text {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.1);
}

.dd-option {
    border-bottom: 1px solid #404040!important;
}

#wrapper .dd-options li {
    border-bottom: 1px solid #404040 !important;
}

#wrapper .dd-options {
    background: #444!important;
    border-color: #404040!important;
}

#wrapper .dd-container label,
#wrapper .dd-container a {
    color: #eee!important;
}

#wrapper .dd-options li a:hover,
#wrapper .dd-options li.dd-option-selected a {
    background-color: #333 !important;
    color: #fff !important;
}

#search-text-top:focus {
    -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, .9);
    -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, .9);
    -box-shadow: 1px 1px 0px rgba(0, 0, 0, .9);
    box-shadow: 1px 1px 0px rgba(0, 0, 0, .9);
}

ul.nav-menu li.nav-hover ul {
    background: #0200b2;
}

ul.nav-menu ul li:hover>a,
ul.nav-menu li.current-menu-item>a,
ul.nav-menu li.current-menu-ancestor>a {
    border-top-color: #0200b2!important;
}

ul.nav-menu li.current-menu-ancestor li.current-menu-item>a,
ul.nav-menu li.current-menu-ancestor li.current-menu-parent>a {
    border-top-color: #0200b2;
}

ul.nav-menu ul {
    border: 1px solid #01007d;
    border-bottom: 0;
}

ul.nav-menu ul li a {
    border-color: #01007d!important;
}

ul.nav-menu li {
    border-left-color: #01007d;
    border-right-color: #0200b2;
}

.menu-header {
    background: #0200b2;
    background: url(../img/dark/trans.png) 0px -10px repeat-x, -moz-linear-gradient(center top, #0200b2 20%, #01007d 100%);
    background: url(../img/dark/trans.png) 0px -10px repeat-x, -webkit-gradient(linear, left top, left bottom, color-stop(.2, #0200b2), color-stop(1, #01007d)) !important;
    background: url(../img/dark/trans.png) 0px -10px repeat-x, -o-linear-gradient(top, #0200b2, #01007d) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0200b2', endColorstr='#01007d');
    border-color: #01007d;
}

body #header.sticky-header {
    background: #0200b2!important;
    border-bottom-color: #01007d;
}

ul.nav-menu li.current-menu-item,
ul.nav-menu li.current-menu-ancestor,
ul.nav-menu li:hover {
    border-right-color: #0200b2!important;
}

.header,
.footer {
    background-image: url(../img/pattern_6.png);
}

.menu-back {
    background-color: #595959;
    background: -webkit-gradient(radial, center center, 0, center center, 460, from(#595959), to(#353535));
    background: -webkit-radial-gradient(circle, #595959, #353535);
    background: -moz-radial-gradient(circle, #595959, #353535);
    background: -o-radial-gradient(circle, #595959, #353535);
    background: -ms-radial-gradient(circle, #595959, #353535);
}

.da-dots span {
    background: #3e3e3e
}


/**
 * Posts Layout
 * 
 */

.home .type-post .entry-content,
.archive .type-post .entry-content,
.search .type-post .entry-content,
.page-template-blog-page-php .type-post .entry-content {
    font-size: 13px;
}

.entry-content {
    margin-top: 25px;
}

.home .odd0,
.archive .odd0,
.search .odd0,
.page-template-blog-page-php .odd0 {
    clear: both;
}

.home .odd1,
.archive .odd1,
.search .odd1,
.page-template-blog-page-php .odd1 {
    margin-right: 0px;
}

.home .entry-title,
.entry-title a,
.archive .entry-title,
.search .entry-title,
.page-template-blog-page-php .entry-title {
    font-size: 120%!important;
    line-height: 120%!important;
    margin-bottom: 0;
}

.home .entry-header,
.archive .entry-header,
.search .entry-header,
.page-template-blog-page-php .entry-header {
    font-size: 12px;
    padding: 0;
}

.home .published strong,
.archive .published strong,
.search .published strong,
.page-template-blog-page-php .published strong {
    font-size: 15px;
    line-height: 15px;
}

.home .type-post .comment-count a,
.archive .type-post .comment-count a,
.search .type-post .comment-count a,
.page-template-blog-page-php .type-post .comment-count a {
    color: #bfbfbf;
    background: url(../img/comment.png) 0 9px no-repeat;
    text-decoration: none;
    position: relative;
    bottom: -9px;
    border: none;
    padding: 8px 10px 8px 18px;
}

.home .hfeed,
.archive .hfeed,
.single .hfeed,
.page .hfeed,
.page-template-blog-page-php .hfeed {
    margin-right: 0px;
}

.home .type-post .entry-footer,
.archive .type-post .entry-footer,
.search .type-post .entry-footer,
.page-template-blog-page-php .type-post .entry-footer {
    float: left;
    width: 100%
}

.home .type-post .comment-count,
.archive .type-post .comment-count,
.search .type-post .comment-count,
.page-template-blog-page-php .type-post .comment-count {
    background: none!important;
    padding-right: 0;
}

#logo,
#logo a {
    font: bold 39px Roboto;
    letter-spacing: -.03em;
}

#tagline {
    font: normal 13px Roboto;
}

.entry-title,
.entry-title a,
.page-title {
    font: normal 28px Roboto;
    color: !important;
}

body,
input,
textarea,
.entry-content {
    font-style: normal;
    font-size: 16px!important;
    font-family: Roboto;
    line-height: 1.5em;
}

body {
    color: !important;
}

ul.nav-menu a {
    font: normal 14px Roboto;
    color: !important;
}

#bootstrap-slider .carousel-inner .img-responsive {
    display: block;
    height: auto;
    width: 100%;
}

#bootstrap-slider .carousel-caption h2 {
    font: bold 36px Roboto!important;
    color: #82d356;
}

#bootstrap-slider .carousel-caption p {
    font: normal 18px Roboto;
}

.da-slide h2 {
    font: normal 36px Roboto!important;
}

.da-slide p {
    font: normal 18px Roboto;
}

#slide_holder .featured-title a {
    font: normal 36px Roboto!important;
}

#slide_holder p {
    font: normal 18px Roboto;
}

#backtotop {
    right: 3%;
}

#wrapper {
    position: relative;
    margin: 0 auto 30px auto !important;
    background: #f9f9f9;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

#wrapper:before {
    bottom: -34px;
    background: url(../img/shadow.png) no-repeat scroll center top!important;
    left: 0px;
    position: absolute;
    z-index: -1;
    height: 7px;
    bottom: -7px;
    content: "";
    width: 100%;
}

a,
.entry-content a:link,
.entry-content a:active,
.entry-content a:visited,
#secondary a:hover,
#secondary-2 a:hover {
    color: #4b49a0;
}

#rss,
#email-newsletter,
#facebook,
#twitter,
#instagram,
#skype,
#youtube,
#flickr,
#linkedin,
#plus,
#pinterest,
#tumblr {
    color: #999999;
}

#rss,
#email-newsletter,
#facebook,
#twitter,
#instagram,
#skype,
#youtube,
#flickr,
#linkedin,
#plus,
#pinterest,
#tumblr {
    font-size: normal;
}

.menu-back {
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.menu-back {
    background-repeat: no-repeat;
}

.content-box-1 i {
    color: #0066bf;
}

.content-box-2 i {
    color: #81d742;
}

.content-box-3 i {
    color: #78665e;
}

.content-box-4 i {
    color: #82a4fa;
}


/* Extra small devices (phones, <768px) */

@media (max-width: 768px) {
    .da-slide h2,
    #bootstrap-slider .carousel-caption h2 {
        font-size: 120%!important;
        letter-spacing: 1px;
    }
    #slide_holder .featured-title a {
        font-size: 80%!important;
        letter-spacing: 1px;
    }
    .da-slide p,
    #slide_holder p,
    #bootstrap-slider .carousel-caption p {
        font-size: 90%!important;
    }
}


/* Small devices (tablets, 768px) */

@media (min-width: 768px) {
    .da-slide h2 {
        font-size: 180%;
        letter-spacing: 0;
    }
    #slide_holder .featured-title a {
        font-size: 120%;
        letter-spacing: 0;
    }
    .da-slide p,
    #slide_holder p {
        font-size: 100%;
    }
}


/* Large devices (large desktops) */

@media (min-width: 992px) {
    .da-slide h2 {
        font-size: 36px;
        line-height: 1em;
    }
    #slide_holder .featured-title a {
        font-size: 36px;
        line-height: 1em;
    }
    .da-slide p {
        font-size: 18px;
    }
    #slide_holder p {
        font-size: 18px;
    }
}

.widget-title {
    font-size: 19px !important;
}

.widget-title {
    font-family: Roboto !important;
}

.widget-title {
    font-style: bold !important;
}

.widget-title {
    color: #5aa806 !important;
}

.widget-content {
    font-size: 13px !important;
}

.widget-content {
    font-family: Roboto !important;
}

.widget-content {
    font-style: normal !important;
}

.entry-content h1 {
    font-size: 46px !important;
}

.entry-content h1 {
    font-family: Roboto !important;
}

.entry-content h1 {
    font-style: normal !important;
}

.entry-content h2 {
    font-size: 40px !important;
}

.entry-content h2 {
    font-family: Roboto !important;
}

.entry-content h2 {
    font-style: normal !important;
}

.entry-content h3 {
    font-size: 34px !important;
}

.entry-content h3 {
    font-family: Roboto !important;
}

.entry-content h3 {
    font-style: normal !important;
}

.entry-content h4 {
    font-size: 27px !important;
}

.entry-content h4 {
    font-family: Roboto !important;
}

.entry-content h4 {
    font-style: normal !important;
}

.entry-content h5 {
    font-size: 20px !important;
}

.entry-content h5 {
    font-family: Roboto !important;
}

.entry-content h5 {
    font-style: normal !important;
}

.entry-content h6 {
    font-size: 14px !important;
}

.entry-content h6 {
    font-family: Roboto !important;
}

.entry-content h6 {
    font-style: normal !important;
}