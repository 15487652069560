@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?da7745');
  src:  url('../fonts/icomoon.eot?da7745#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?da7745') format('truetype'),
    url('../fonts/icomoon.woff?da7745') format('woff'),
    url('../fonts/icomoon.svg?da7745#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-phone:before {
  content: "\f095";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-bicycle:before {
  content: "\f206";
}
