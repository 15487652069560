@import 'reset.scss';
@import 'fonts.scss';
@import 'styles.scss';
@import 'parallax.scss';
@import 'front.scss';
@import 'dynamic.scss';
@import 'bootstrap.scss';
@import 'bootstrap-theme.scss';
@import 'lightbox.scss';

.header {
    position: relative;
    overflow-x: hidden;
}

.article {
    .panel-grid-cell {
        position: relative;
    }
    h3 {
        font-size: 34px;
        color: #5AA806;
    }
    h5 {
        font-size: 20px;
    }
    p {
        margin: 16px 0;
        text-align: justify;
    }
    hr {
        border: 0;
        background: url(../img/widget-shadow.png) no-repeat top center;
        content: "";
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
        margin: 16px auto;
    }
    hr + h3 {
        margin-top: 64px;
    }
}

/** phone div */
.hover-contact {
    position: absolute;
    right: 0;
    top: 31px;
    padding: 0px 16px 0px 32px;
    min-height: 48px;
    color: #333;
    border: 3px solid rgba(90, 168, 6, 0);
    background: rgba(255, 255, 255, 0);
    z-index: 1000;
    .fa-phone {
        top: -3px;
        background: rgba(255, 255, 255, 0);
        position: absolute;
        left: 0;
        font-size: 42px;
        width: 48px;
        text-align: center;
        height: 48px;
        vertical-align: middle;
    }
    a {
        padding: 11px 0;
        display: inline-block;
        border-color: red;
        font-weight: bold;
        &:hover .text {
            border-bottom: 1px solid inherit;
        }
    }
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}
.pull-phone {
    border: 1px solid #ccc;
    background: #d7d7d7;
    box-shadow: 0 0 6px 0 #ccc inset;
    border-radius: 8px;
}
.phone-contact {
    position: relative;
    .text {
        margin-left: 10px;
        display: inline-block;
        width: 140px;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        vertical-align: middle;
        line-height: 14px;
    }
}
.header a {
    color: #555;
    text-align: right;
}
.hidden-phone {
    background: rgb(255, 255, 255);
    border: 3px solid #5AA806;
    color: #5AA806;
    
    & a {
        color: #5AA806;
    }
    & .fa-phone {
        top: 9px;
    }
}
.hidden-phone:hover {
    transform: translateX(-150px);
}

@media screen and (min-width: 992px) {
    .hidden-phone {
        .fa-phone {
            left: 12px;
            top: 12px;
            font-size: 48px;
            line-height: 38px;
        }
        a {
            padding-top: 18px;
            padding-bottom: 18px;
            
        }
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        padding-left: 52px;
    }
}
@media (min-width: 701px) and (max-width: 991px) {
    
        .hover-contact .fa-phone {
            top: 9px;
            font-size: 30px;
        }
}
@media screen and (max-width: 700px) {
    .hover-contact {
        position: fixed;
        right: -155px !important;
        background: rgb(255, 255, 255);
        border: 3px solid #5AA806;
        color: #5AA806;
        transition: all 0.5s;
        .fa-phone {
            top: 9px;
            font-size: 30px;
        }
        &:hover {
            transform: translateX(-150px);
        }
    }
}
@media screen and (max-width: 640px) {
    #bootstrap-slider .carousel-caption {
        padding-bottom: 10px;
    }
    #bootstrap-slider .carousel-caption p {
        display: block;
    }
}
@media screen and (max-width: 420px) {
    #bootstrap-slider .carousel-inner .img-responsive {
        max-width: auto;
        width: auto;
        margin-left: 50%;
        height: 166px;
        transform: translateX(-50%);
    }

}
