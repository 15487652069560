.da-slider{
	width: 100%;
	min-height: 300px;
	position: relative;
  z-index:1;
	margin: 0px auto;
  padding-bottom:20px;
	overflow: hidden;
	background: transparent;
	-webkit-transition: background-position 1.4s ease-in-out 0.3s;
	-moz-transition: background-position 1.4s ease-in-out 0.3s;
	-o-transition: background-position 1.4s ease-in-out 0.3s;
	-ms-transition: background-position 1.4s ease-in-out 0.3s;
	transition: background-position 1.4s ease-in-out 0.3s;
}
.da-slide{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	text-align: left;
}
.da-slide-current{
	z-index: 1000;
}
.da-slider-fb .da-slide{
	left: 100%;
}
.da-slider-fb  .da-slide.da-slide-current{
	left: 0px;
}
.da-slide h2,
.da-slide p,
.da-slide .da-link{
	position: absolute;
	opacity: 0;
	left: 110%;
  }

.da-slider-fb .da-slide h2,
.da-slider-fb .da-slide p,
.da-slider-fb .da-slide .da-link{
	left: 10%;
	opacity: 1;
}
.da-slider-fb .da-slide .da-img{
	left: 65%;
	opacity: 1;
}
.da-slide h2{
	color: #fff;
	font-size: 36px;
  margin:0;
	width: 50%;
	top: 30px;
	white-space: pre-wrap;
	z-index: 10;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	font-weight: 700;
}
.da-slide p{
	width: 50%;
	top: 115px;
  margin:0;
	color: #ddd;
	font-size: 18px;
	line-height: 26px;
	overflow: hidden;
	font-style: italic;
	font-weight: 400;
}
.da-slide .da-img{
	position: absolute;
	opacity: 0;
	text-align: center;
	width: 30%;
	top: 20px;
	height: 256px;
	line-height: 320px;
	left: 65%; /*60%*/
}
.da-slide .da-link{
	top: 230px; /*depends on p height*/
	text-shadow:0 1px 2px #111111!important;padding: 3px 15px; cursor: pointer;
-webkit-border-radius:3px;-moz-border-radius:3px;-border-radius:3px;border-radius:3px;border:1px solid #222;
background:#505050;
background:-moz-linear-gradient(center top , #606060 20%, #505050 100%);
   background:-webkit-gradient(linear,left top,left bottom,color-stop(.2, #606060),color-stop(1, #505050));
    background:-o-linear-gradient(top, #606060,#505050);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#606060', endColorstr='#505050');
-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 5px rgba(0, 0, 0, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);color:#fff;text-shadow:0 1px 0px #000;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
}
.da-slide .da-link:hover{
	text-decoration:none;
}
.da-dots{
	width: 100%;
	position: absolute;
	text-align: center;
	left: 0px;
	bottom: 10px;
	z-index: 2000;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.da-dots span{
	display: inline-block;
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: rgba(0,0,0,.2);
	margin: 3px;
	cursor: pointer;
	box-shadow: 
		1px 1px 1px rgba(0,0,0,0.1) inset, 
		1px 1px 1px rgba(255,255,255,0.1);
}
.da-dots span.da-dots-current:after{
	content: '';
	width: 8px;
	height: 8px;
	position: absolute;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	background: rgb(255,255,255);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(47%,rgba(246,246,246,1)), color-stop(100%,rgba(237,237,237,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%);
	background: linear-gradient(top, rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );
}
.da-arrows{
	-moz-user-select: none;
	-webkit-user-select: none;
}
.da-arrows span{
	position: absolute;
	top: 50%;
  margin-top:-15px;
	height: 30px;
	width: 30px;
	cursor: pointer;
	z-index: 2000;
	opacity: 0;
	-webkit-transition: opacity 0.4s ease-in-out 0.2s;
	-moz-transition: opacity 0.4s ease-in-out 0.2s;
	-o-transition: opacity 0.4s ease-in-out 0.2s;
	-ms-transition: opacity 0.4s ease-in-out 0.2s;
	transition: opacity 0.4s ease-in-out 0.2s;
}
.da-slider:hover .da-arrows span{
	opacity: 1;
}
.da-arrows span:after{
	content: '';
	position: absolute;
	width: 40px;
	height: 40px;
	top: 0px;
	left: 0px;
	background: transparent url(../../images/left-ar.png) no-repeat top left;
}
.da-arrows span:hover:after{
	box-shadow: 1px 1px 4px rgba(0,0,0,0.3);
}
.da-arrows span:active:after{
	box-shadow: 1px 1px 1px rgba(255,255,255,0.1);
}
.da-arrows span.da-arrows-next:after{
	background: transparent url(../../images/right-ar.png) no-repeat top left;
}
.da-arrows span.da-arrows-prev{
	left: 25px;
}
.da-arrows span.da-arrows-next{
	right: 35px;
}

.da-slide-current h2,
.da-slide-current p,
.da-slide-current .da-link{
	left: 10%;
	opacity: 1;
}
.da-slide-current .da-img{
	left: 65%;
	opacity: 1;
}
/* Animation classes and animations */

/* Slide in from the right*/
.da-slide-fromright h2{
	-webkit-animation: fromRightAnim1 0.6s ease-in 0.8s both;
	-moz-animation: fromRightAnim1 0.6s ease-in 0.8s both;
	-o-animation: fromRightAnim1 0.6s ease-in 0.8s both;
	-ms-animation: fromRightAnim1 0.6s ease-in 0.8s both;
	animation: fromRightAnim1 0.6s ease-in 0.8s both;
}
.da-slide-fromright p{
	-webkit-animation: fromRightAnim2 0.6s ease-in 0.8s both;
	-moz-animation: fromRightAnim2 0.6s ease-in 0.8s both;
	-o-animation: fromRightAnim2 0.6s ease-in 0.8s both;
	-ms-animation: fromRightAnim2 0.6s ease-in 0.8s both;
	animation: fromRightAnim2 0.6s ease-in 0.8s both;
}
.da-slide-fromright .da-link{
	-webkit-animation: fromRightAnim3 0.4s ease-in 1.2s both;
	-moz-animation: fromRightAnim3 0.4s ease-in 1.2s both;
	-o-animation: fromRightAnim3 0.4s ease-in 1.2s both;
	-ms-animation: fromRightAnim3 0.4s ease-in 1.2s both;
	animation: fromRightAnim3 0.4s ease-in 1.2s both;
}
.da-slide-fromright .da-img{
	-webkit-animation: fromRightAnim4 0.6s ease-in 0.8s both;
	-moz-animation: fromRightAnim4 0.6s ease-in 0.8s both;
	-o-animation: fromRightAnim4 0.6s ease-in 0.8s both;
	-ms-animation: fromRightAnim4 0.6s ease-in 0.8s both;
	animation: fromRightAnim4 0.6s ease-in 0.8s both;
}
@-webkit-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-moz-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-o-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-ms-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}
/* Slide in from the left*/
.da-slide-fromleft h2{
	-webkit-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
	-moz-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
	-o-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
	-ms-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
	animation: fromLeftAnim1 0.6s ease-in 0.6s both;
}
.da-slide-fromleft p{
	-webkit-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
	-moz-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
	-o-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
	-ms-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
	animation: fromLeftAnim2 0.6s ease-in 0.6s both;
}
.da-slide-fromleft .da-link{
	-webkit-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
	-moz-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
	-o-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
	-ms-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
	animation: fromLeftAnim3 0.4s ease-in 1.2s both;
}
.da-slide-fromleft .da-img{
	-webkit-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
	-moz-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
	-o-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
	-ms-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
	animation: fromLeftAnim4 0.6s ease-in 0.6s both;
}
@-webkit-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-moz-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-o-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@-ms-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}

@keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 65%; opacity: 1; }
}
/* Slide out to the right */
.da-slide-toright h2{
	-webkit-animation: toRightAnim1 0.6s ease-in 0.6s both;
	-moz-animation: toRightAnim1 0.6s ease-in 0.6s both;
	-o-animation: toRightAnim1 0.6s ease-in 0.6s both;
	-ms-animation: toRightAnim1 0.6s ease-in 0.6s both;
	animation: toRightAnim1 0.6s ease-in 0.6s both;
}
.da-slide-toright p{
	-webkit-animation: toRightAnim2 0.6s ease-in 0.3s both;
	-moz-animation: toRightAnim2 0.6s ease-in 0.3s both;
	-o-animation: toRightAnim2 0.6s ease-in 0.3s both;
	-ms-animation: toRightAnim2 0.6s ease-in 0.3s both;
	animation: toRightAnim2 0.6s ease-in 0.3s both;
}
.da-slide-toright .da-link{
	-webkit-animation: toRightAnim3 0.4s ease-in both;
	-moz-animation: toRightAnim3 0.4s ease-in both;
	-o-animation: toRightAnim3 0.4s ease-in both;
	-ms-animation: toRightAnim3 0.4s ease-in both;
	animation: toRightAnim3 0.4s ease-in both;
}
.da-slide-toright .da-img{
	-webkit-animation: toRightAnim4 0.6s ease-in both;
	-moz-animation: toRightAnim4 0.6s ease-in both;
	-o-animation: toRightAnim4 0.6s ease-in both;
	-ms-animation: toRightAnim4 0.6s ease-in both;
	animation: toRightAnim4 0.6s ease-in both;
}
@-webkit-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim4{
	0%{ left: 65%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-moz-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim4{
	0%{ left: 65%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-o-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim4{
	0%{ left: 65%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-ms-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim4{
	0%{ left: 65%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim4{
	0%{ left: 65%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
/* Slide out to the left*/
.da-slide-toleft h2{
	-webkit-animation: toLeftAnim1 0.6s ease-in both;
	-moz-animation: toLeftAnim1 0.6s ease-in both;
	-o-animation: toLeftAnim1 0.6s ease-in both;
	-ms-animation: toLeftAnim1 0.6s ease-in both;
	animation: toLeftAnim1 0.6s ease-in both;
}
.da-slide-toleft p{
	-webkit-animation: toLeftAnim2 0.6s ease-in 0.3s both;
	-moz-animation: toLeftAnim2 0.6s ease-in 0.3s both;
	-o-animation: toLeftAnim2 0.6s ease-in 0.3s both;
	-ms-animation: toLeftAnim2 0.6s ease-in 0.3s both;
	animation: toLeftAnim2 0.6s ease-in 0.3s both;
}
.da-slide-toleft .da-link{
	-webkit-animation: toLeftAnim3 0.4s ease-in both;
	-moz-animation: toLeftAnim3 0.4s ease-in both;
	-o-animation: toLeftAnim3 0.4s ease-in both;
	-ms-animation: toLeftAnim3 0.4s ease-in both;
	animation: toLeftAnim3 0.4s ease-in both;
}
.da-slide-toleft .da-img{
	-webkit-animation: toLeftAnim4 0.6s ease-in 0.6s both;
	-moz-animation: toLeftAnim4 0.6s ease-in 0.6s both;
	-o-animation: toLeftAnim4 0.6s ease-in 0.6s both;
	-ms-animation: toLeftAnim4 0.6s ease-in 0.6s both;
	animation: toLeftAnim4 0.6s ease-in 0.6s both;
}
@-webkit-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim4{
	0%{ left: 65%;  opacity: 1; }
	40%{ left: 70%;  opacity: 1; }
	90%{ left: 0%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-moz-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim4{
	0%{ left: 65%;  opacity: 1; }
	40%{ left: 70%;  opacity: 1; }
	90%{ left: 0%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-o-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim4{
	0%{ left: 65%;  opacity: 1; }
	40%{ left: 70%;  opacity: 1; }
	90%{ left: 0%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-ms-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim4{
	0%{ left: 65%;  opacity: 1; }
	40%{ left: 70%;  opacity: 1; }
	90%{ left: 0%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim4{
	0%{ left: 65%;  opacity: 1; }
	40%{ left: 70%;  opacity: 1; }
	90%{ left: 0%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}